import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useCookies } from "react-cookie";
import Home from "./Pages/Home/Home";
import LoginPage from "./Pages/Login/LoginPage";
import TokenCheck from "./Functions/TokenCheck";
import Admin from "./Admin/Admin";
import EnterPassword from "./Pages/Login/Components/EnterPassword";
import Register from "./Pages/Login/Components/Register";

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [IsLoggedIn, setIsLoggedIn] = useState("LOADING");

  useEffect(() => {
    const checkToken = async () => {
      if (cookies.refresh_token !== undefined) {
        let res = await TokenCheck(cookies, setCookie);
        if (res.status !== undefined && res.status === "PASSED") {
          console.log("[ACCESS TOKEN]: ", res.access_token);
          setIsLoggedIn(true);
        } else {
          clearAllCookies();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };
    checkToken();
  }, [cookies, setCookie]);

  const toggleLogin = (bool) => {
    setIsLoggedIn(bool);
  };

  const clearAllCookies = () => {
    Object.keys(cookies).forEach((cookieName) => {
      removeCookie(cookieName, { path: "/" });
    });
  };

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          index
          element={
            IsLoggedIn === true ? (
              <Home
                onIfLoggedIn={IsLoggedIn}
                onSetIfLoggedIn={toggleLogin}
                onClearCookies={clearAllCookies}
              />
            ) : IsLoggedIn === false ? (
              <LoginPage
                onIfLoggedIn={IsLoggedIn}
                onSetIfLoggedIn={toggleLogin}
              />
            ) : (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )
          }
        />
        <Route path="signup" element={<Register />} />
        {/* Uncomment when ready */}
        {/* <Route path="CPO-Admin" element={<Admin />} /> */}
        {/* <Route path="nsp/v1/:ResetPassword" element={<EnterPassword />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
