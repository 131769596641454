import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2';

const Login = (props) => {
    const navigate = useNavigate();
    // Use the useCookies hook to access and manipulate cookies
    const [cookies, setCookie] = useCookies('');
    const [Username, setUsername] = useState(''); // Initialize 'Username' with an initial value of ''
    const [inputPassword, setInputPassword] = useState(''); // Initialize 'inputPassword' with an initial value of ''
    const [isLoginError, setIsLoginError] = useState(false);  // Initialize 'errorLogin' with an initial value of false
    const [IsInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isLoginFailed, setIslLoginFailed] = useState(false);
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/; // EMAIL ADDRESS REGEX 

    //======================================================================================================= 3.1 Login Function to handle login form submission
    const LoginSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior)
        if (Username === '' || inputPassword === '') {  // Check if email or password is empty)
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                Email address and password are required.
            `,
                showConfirmButton: false,
                timer: 1500
            });
            setIsInvalidEmail(false);
            setIsLoginError(true);

        } else {
            await fetch(process.env.REACT_APP_SERVICE_URL + "/ocpi/cpo/api/auth/v1/login", {  //==================== 3.1.1 Make a POST request to obtain a token for login
                method: 'POST',
                body: JSON.stringify({
                    "username": Username,
                    "password": inputPassword
                }),
                headers: {
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache',
                    "Authorization": "Basic " + process.env.REACT_APP_BASIC_TOKEN, // Include the authentication token in the headers)
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json()) //============================================================ 3.1.2 Parse the JSON response
                .then((res) => {
                    console.log(res)
                    // Handle the response based on the status)
                    if (res.status === 200) {
                        setIslLoginFailed(false);
                        setIsLoginError(false);
                        setIsInvalidEmail(false);
                        console.log("FUCK!")
                        //======================================================================================= 3.1.3 Set cookies with authentication information)
                        setCookie('auth_token', res.data.access_token, { path: '/' });
                        setCookie('access_token_expiresIn', res.data.access_expires_in * 1000, { path: '/' });
                        setCookie('refresh_token', res.data.refresh_token, { path: '/' });
                        setCookie('refresh_token_expiresIn', res.data.refresh_expires_in, { path: '/' });
                        setCookie('type', res.data.role, { path: '/' });
                        props.onSetIfLoggedIn(true);
                        // Display success message to the user)
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Successful Login!",
                            html: `
                        You are now logged in to the Find EV Plug.
                    `,
                            showConfirmButton: false,
                            timer: 2000
                        });

                    } else {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            html: `
                        Incorrect username or passsword. Please try again.
                    `,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setIsLoginError(false);
                        setIsInvalidEmail(false);
                        setIslLoginFailed(true);
                    }
                })
                .catch(error => console.log('error', error)); //================================================= 3.1.4 Handle errors (e.g., network issues) by logging to the console)
        }
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form validate-form">
                        <span className="login100-form-title p-b-43">
                            <img src={props.Logo} className='img-fluid' />
                        </span>
                        <h5 className="text-center mb-2"><b>eMSP Login</b></h5>
                        {/* --------------------------------------------------------------------------------------Input Email Address--------------------------------------------------------------------------------- */}
                        <TextField
                            error={isLoginError && Username === '' ? true : isLoginFailed ? true : false}
                            label="Username"
                            className="form-control mb-3"
                            value={Username}
                            onChange={((e) => setUsername(e.target.value))} //-- Update 'Username' with the value entered in the input field
                            helperText=
                            {
                                isLoginError && Username === '' ? "This field is required."
                                    : isLoginFailed ? "Please check your Username." : ""
                            }
                        />
                        {/* --------------------------------------------------------------------------------------Input Password--------------------------------------------------------------------------------- */}
                        <FormControl variant="outlined" className="form-control mb-3">
                            <InputLabel htmlFor="outlined-adornment-password"
                                error={isLoginError && inputPassword === '' ? true : isLoginFailed ? true : false}
                            >   Password
                            </InputLabel>
                            <OutlinedInput
                                error={isLoginError && inputPassword === '' ? true : isLoginFailed ? true : false}
                                id="outlined-adornment-password"
                                value={inputPassword}
                                onChange={((e) => setInputPassword(e.target.value))} //-- Update 'inputPassword' with the value entered in the input field
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                            <FormHelperText error={isLoginError && inputPassword === '' ? true : isLoginFailed ? true : false}>
                                {
                                    isLoginError && Username === '' ? "A password is required"
                                        : isLoginFailed ? "Please check your Password." : ""
                                }
                            </FormHelperText>
                        </FormControl>
                        <div className="flex-sb-m w-full p-t-3 p-b-32 d-flex flex-row-reverse">
                            <div className='mb-2'>
                                <a href="#" onClick={props.toForgotPassword}>
                                    <b>Forgot Password?</b>
                                </a>
                            </div>
                        </div>
                        <div className="container-login100-form-btn">
                            {/* ---------------------------------------------------------------------------------- Submit Button  ----------------------------------------------------------------------------------*/}
                            <button className="login100-form-btn mb-3" onClick={LoginSubmit}>
                                Sign In
                            </button>
                            <div>Don't have an account? &nbsp;
                                <a href="/signup" >
                                    <b>Sign Up</b>
                                </a>
                            </div>
                        </div>
                    </form>
                    {/* <img src={props.LoginImage} className='img-fluid login100-more'/> */}
                </div>
            </div>
        </div>
    );
}

export default Login;
